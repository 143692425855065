import {
  CLEAR_PROJECT_DATA,
  CREATE_SESSION,
  CREATE_SESSION_FAILED,
  CREATE_SESSION_SUCCESS,
  FETCH_SESSIONS,
  FETCH_SESSIONS_FAILED,
  FETCH_SESSIONS_SUCCESS,
  GET_FACEBOOK_COMPLETIONS_SUCCESS,
  SET_PRESET,
  USER_DATA_UPDATED,
} from 'containers/App/constants';

export const initialState = {
  saving: false,
  fetching: false,
  userEmail: '',
  data: {},
  hash: '',
  recentlyUpdated: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SESSIONS:
      return { ...state, userEmail: payload.email, fetching: true };

    case FETCH_SESSIONS_FAILED:
    case FETCH_SESSIONS_SUCCESS:
      return { ...state, fetching: false };

    case CREATE_SESSION:
      return { ...state, saving: true };

    case USER_DATA_UPDATED:
    case GET_FACEBOOK_COMPLETIONS_SUCCESS:
    case SET_PRESET:
    case CLEAR_PROJECT_DATA:
      return { ...state, recentlyUpdated: true };

    case CREATE_SESSION_SUCCESS: {
      if (payload.hash === state.hash) {
        return { ...state, saving: false, recentlyUpdated: false };
      }

      // strip state data from payload
      const { data, ...session } = payload.data;
      return {
        ...state,
        saving: false,
        data: session,
        hash: payload.hash,
        recentlyUpdated: true,
      };
    }

    case CREATE_SESSION_FAILED:
      return { ...state, saving: false };

    default:
      return state;
  }
};

export const getSessions = state => state.sessions;
export const isSaving = state => getSessions(state).saving;
export const isFetching = state => getSessions(state).fetching;
export const hasRecentlyUpdated = state => getSessions(state).recentlyUpdated;
export const getUserEmail = state => getSessions(state).userEmail;
export const getHash = state => getSessions(state).hash;
export const getSession = state => getSessions(state).data;
export const getSessionCreatedAt = state => getSession(state).createdAt;
