import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth0 } from '@auth0/auth0-react';
import NavBar from 'components/NavBar';
import CatCard from 'components/CatCard';
import httpClient from 'common/http-client';
import Bugsnag from '@bugsnag/js';
import throttle from 'lodash/throttle';
import { createSession, fetchProjects, fetchSessions } from './actions';
import { hasRecentlyUpdated, isFetching } from './sessions/reducer';

const STYLES = {
  height: '100%',
  width: '100%',
};

const IDLE_TIMEOUT = 60 * 60 * 1000 * 2; // 2 hours in ms
const SAVE_WAIT_TIME = 2000;

function AppWrapper({
  loading,
  children,
  onLoadActions,
  getUserSessions,
  saveSession,
}) {
  const {
    user,
    isLoading,
    isAuthenticated,
    error,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const { store } = useContext(ReactReduxContext);

  const onIdle = () => {
    logout({ returnTo: window.location.origin });
  };

  useIdleTimer({
    onIdle,
    timeout: IDLE_TIMEOUT,
    throttle: 500,
    disabled: !isAuthenticated,
  });

  useEffect(() => {
    const save = throttle(
      () => {
        if (isAuthenticated) {
          const state = store.getState();

          if (hasRecentlyUpdated(state)) {
            saveSession(user);
          }
        }
      },
      SAVE_WAIT_TIME,
      { leading: false },
    );

    const unsubscribe = store.subscribe(save);

    return () => {
      save.cancel();
      unsubscribe();
    };
  }, [store, isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated) {
      onLoadActions.forEach(action => action());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      Bugsnag.setUser(user.sub, user.email, user.name);
      getUserSessions(user);
    }
  }, [user]);

  useEffect(() => {
    httpClient.setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  if (isLoading || loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <div>
      <NavBar />
      <div style={STYLES}>{isAuthenticated ? children : <CatCard />}</div>
    </div>
  );
}

AppWrapper.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  onLoadActions: PropTypes.arrayOf(PropTypes.func),
  getUserSessions: PropTypes.func,
  saveSession: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: isFetching(state),
});

const mapDispatchToProps = {
  fetchProjects,
  getUserSessions: fetchSessions,
  saveSession: createSession,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onLoadActions: [dispatchProps.fetchProjects],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AppWrapper);
